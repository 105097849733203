/* eslint-disable no-console */
import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';
import { FormattedMessage } from 'react-intl';

import ErrorBoundary from '../ErrorBoundary';
import PageNotFound from '../common/PageNotFound';
import NoAccessPage from '../common/NoAccessPage';
import createLegacyExternalWidgets from './utils';

const NavigationRoutes = ({
  modules = [],
  baseRoutes = [],
  locale,
  theme,
  items
}) => {
  const [stateModules, setStateModules] = React.useState([]);

  useLayoutEffect(() => {
    const createRoutes = module => {
      try {
        if (!module.default) {
          console.error(`No default export found in the module ${module.getName()}`);
          return null;
        }

        if (typeof module.default.routes !== 'function') {
          console.warn(
            `No navigation routes defined in the module ${module.getName()}. Your components might not be visible`
          );
          return null;
        }

        const baseRoute = baseRoutes.find(route => route.bundlesFolder === module.getName());
        if (!baseRoute) {
          console.warn(
            `No base navigation route defined for module ${module.getName()}. Please check the json config`
          );
          return null;
        }

        const moduleRoutes = module.default.routes({
          baseRoute: baseRoute.baseRoute,
          assetsPath: module.getDeployedPath()
        });
        if (!moduleRoutes || !modules.length || modules.length === 0) {
          console.warn(`No base navigation route defined for module ${module.getName()}.`);
          return null;
        }

        return moduleRoutes.map(route => {
          // TODO may be check that route.path is prefixed by baseRoute.path
          // Or automatically add the prefix here and not pass the baseRoute to routes fct.
          // Micro Apps might have issue to generate <NavLink to="???" />

          if (route.path === '/dashboard') {
            return (
              <Route
                key={route.path}
                exact
                path={route.path}
                render={props => {
                  const Component = route.component;
                  return Component ? (
                    <ErrorBoundary>
                      <Component
                        {...props}
                        theme={theme}
                        locale={locale}
                        externalWidgets={createLegacyExternalWidgets(modules)}
                      />
                    </ErrorBoundary>
                  ) : (
                    <div data-test='undefined-component' />
                  );
                }}
              />
            );
          }

          return (
            <Route
              key={route.path}
              exact
              path={route.path}
              render={props => {
                const Component = route.component;
                return Component ? (
                  <ErrorBoundary>
                    <Component {...props} theme={theme} locale={locale} />
                  </ErrorBoundary>
                ) : (
                  <div data-test='undefined-component' />
                );
              }}
            />
          );
        });
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    setStateModules(modules?.map(module => createRoutes(module)));

  }, [modules, baseRoutes, locale, theme, items]);

  let catchAll = null;
  if (baseRoutes?.length > 0 && modules?.length > 0 && stateModules?.length > 0) {
    catchAll = <Redirect to='/404' />;
  } else if (baseRoutes?.length === 0 && !modules && !stateModules) {
    catchAll = <Redirect to='/no-access' />;
  } else {
    catchAll = (<Route
      render={() => <FormattedMessage id='global.loading' defaultMessage='Loading...' />}
    />);
  }

  return (
    <Switch>
      <Route
        exact
        path='/'
        render={() => {
          const firstAvailableNavigationItem = items[0];
          let firstAvailableRoute;
          if (firstAvailableNavigationItem) {
            firstAvailableRoute =
              firstAvailableNavigationItem.url || firstAvailableNavigationItem.subItems[0].url;
          } else {
            firstAvailableRoute = '/no-access';
          }

          return <Redirect to={firstAvailableRoute} />;
        }}
      />

      <Route
        path='/myorders'
        render={props => {
          /* eslint-disable react/prop-types */
          let newUrl = props.location.pathname.replace(/myorders/i, 'transactions/online');

          if (props.location.search) {
            newUrl += props.location.search;
          }

          return <Redirect to={newUrl} />;
          /* eslint-enable react/prop-types */
        }}
      />

      {baseRoutes?.length > 0 ? stateModules : null}

      <Route path='/no-access' exact component={NoAccessPage} />

      <Route path='/404' exact component={PageNotFound} />

      {catchAll}
    </Switch>
  );
};

NavigationRoutes.propTypes = {
  locale: PropTypes.string.isRequired,
  modules: PropTypes.arrayOf(PropTypes.object),
  baseRoutes: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

NavigationRoutes.defaultProps = {
  modules: [],
  baseRoutes: []
};

export default NavigationRoutes;
